@import '../../../resources/styles/fonts.scss';

.time-chart-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 600px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 100px;
    .time-chart-filter {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        
        .time-filter {
           display: flex;
           margin-bottom: 30px;
        }
        .party-colors {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .party-color-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: white;
                box-sizing: border-box;
                cursor: pointer;
                width: 42px;
                height: 42px;
                margin: 0 9px;
                margin-bottom: 30px;
                text-align: center;
                opacity: 0.5;
                &:hover {
                    opacity: 1;
                }
                &.active {
                    opacity: 1;
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.788);
                    transform: scale(1.1);
                }
                .party-logo {
                    height: 50%;
                    user-select: none;
                }
            }
        }
    }
    .time-chart {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        svg {
            height: 500px;
            width: 100%;
            overflow: visible;  
            box-shadow : 0px -0px 10000px transparent; /*trick for chrome*/
            .path {
                stroke-dasharray: 6000;
                stroke-dashoffset: 6000;
                animation: animatePath 2s linear forwards;
                opacity: 0.6;
                pointer-events: none;
                &.only-one-party {
                    opacity: 1;
                }
            }
            .indicator-path {
                pointer-events: none;
            }
            .time-chart-year {
                font: $font-body-light;
                font-size: 14px;
                fill: lightgrey;
                pointer-events: none;
                user-select: none;
                &.only-one-party {
                    fill: white;
                }
            }
            .indicator-circle {
                pointer-events: none;
                opacity: 0;
                transition: opactiy 0.3s;
                &.shown {
                    opacity: 0.6;
                }
                &.only-one-party {
                    &.shown {
                        opacity: 1;
                    }
                }
            }
        }
        
        .month-info {
            position: absolute;
            width: 220px;
            left: 10px;
            top: -10px;
            box-sizing: border-box;
            padding: 10px;
            background-color: rgba(0, 0, 0, 0.6);
            pointer-events: none;
            border-radius: 5px;
            opacity: 0;
            animation: fadeInMonthInfo 0.5s forwards;
            color: white;
            .current-month {
                text-transform: capitalize;
            }
            hr {
                border: 0.5px solid white;
            }
            .party-name-and-percent {
                display: flex;
                align-items: center;
                font-size: 13px;
                line-height: 22px;
                .party-color {
                    height: 6px;
                    width: 6px;
                    border: 1px solid white;
                    opacity: 0.9;
                    margin-right: 5px;
                    border-radius: 50%;
                }
                .party-name {
                    text-align: right;
                    width: 130px;
                    text-transform: capitalize;
                }
                .party-percent {
                    margin-left: 10px; 
                    font: $font-body-light;
                    font-size: 13px;
                    line-height: 22px;
                }
            }
           
        }
    }
    
}

@keyframes animatePath {
    to {
        stroke-dashoffset: 0
    }
}

@keyframes fadeInMonthInfo {
    to {
        opacity: 1
    }
}