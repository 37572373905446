@import '../../../../resources/styles/fonts.scss';

.selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 150px;
    height: 40px;
    user-select: none;
    color: white;
    font: $font-body-regular;
    font-weight: lighter;
    margin: 5px;
    cursor: pointer;
    
    &::before {
        height: 15px;
        bottom: 0;
        transform: translateY(100%);
        width: 100%;
        content: '';
        position: absolute;
    }
    .choice {
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-size: 14px;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        background-color: #866752;
        text-transform: capitalize;
    }
    .sort-icon {
        position: absolute;
        right: 10px;
        height: 15%;
        opacity: 1;
    }
    ul {
        color: black;
        width: 100%;
        z-index: 3;
        max-height: 200px;
        overflow: scroll;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: #866752;
        position: absolute;
        top: 40px;
        left: 0;
        font-size: 15px;
        margin: 0;
        padding: 0;
        border-top: none;
        transform-origin: top;
        animation: slideDownSelector 0.3s forwards;
        box-shadow: 0px 2px 2px black;
        .arrow {
            height: 10px;
            width: 10px;
            background-color: #866752;
            transform: translate(20px, -50%) rotate(45deg); 
            position: absolute;
            top: 0;
        }
        li {
            color: white;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            height: 40px;
            padding-left: 10px;
            list-style: none;
            cursor: pointer;
            opacity: 0;
            animation: fadeInChoices 0.3s 0.3s forwards;
            &:hover {
                background-color: #a78b79;
            }
        }
    }

    @keyframes slideDownSelector {
        from {
            transform: translateY(5%) scaleY(0)
        } to {
            transform: translateY(5%) scaleY(1);
        }
    }
    @keyframes fadeInChoices {
        from {
            opacity: 0;
        } to {
            opacity: 1;
        }
    }
}
