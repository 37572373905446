@import '../styles/fonts.scss';


body {
    margin: 0;
    font: $font-body-regular;
}

p {
    margin: 0;
}


  