@import '../../../resources/styles/fonts.scss';

.party-chart-wrapper {
	position: relative;
	z-index: 1;
	width: 100%;
	min-width: 313px;
	height: 400px;
	box-sizing: border-box;
	padding: 0 10%;
	margin-top: 80px;
	.party-chart {
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: space-around;
		padding: 0 30px;
		box-sizing: border-box;
		width: 100%;
		height: calc(100% - 62px);
		margin: 0 auto;
		overflow: hidden;
		.chart-shadow {
			width: 100%;
			height: 20px;
			position: absolute;
			z-index: 0;
			bottom: 0;
		}
		.party-stable-wrapper {
			position: relative;
			height: 100%;
			width: 7%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			transform: scaleY(0);
			animation: staplesFromBottom 1s forwards;
			transform-origin: bottom;
			z-index: 1;
			transition: height 1s;
			
			.party-percent {
				position: absolute;
				bottom: 5px;
				color:white;
				user-select: none;
				pointer-events: none;
				z-index: 3;
				font: $font-body-light;
				font-size: 12px;
				font-weight: bold;
				text-shadow: 0px 0px 1px black;
			
			}
			.party-staple {
				position: relative;
				cursor: pointer;
				border: 5px solid white;
				border-bottom: 0;
				transform-origin: bottom;
				height: 100%;
				width: 100%;
				border-radius: 10px 10px 0 0;
	
				&:hover {
					
				}

				&:after {
					content: '';
					border-radius: 10px;
					pointer-events: none;
					top:0;left:0;right:0;bottom:0px;
					background-color: black;
					transform-origin: bottom;
					transform: rotate(-4deg) skewX(4deg) skewY(4deg) scaleY(0.95); 
					filter: blur(10px);
					opacity: 0.3;
					position: absolute;
					z-index: -1;
				}
			}
		}
	}
	.party-chart-labels {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		height: 50px;
		padding: 0 30px;
		box-sizing: border-box;
		padding-top: 10px;
		.party-label {
			display: flex;
			justify-content: center;
			width: calc(7% + 15px);
			height: 100%;
			user-select: none;
			.party-logo {
				height: 90%;
				-webkit-user-drag: none;
			}
		}
	}
	@media only screen and (max-width: 600px) {
		height: 300px;
		padding: 0;
		.party-chart {
			.party-stable-wrapper {
				.party-percent {
					position: relative;
					bottom: 4px;
					color: black;
					z-index: 1;
					font-weight: bold;
					font-size: 13px;
					width: 30px;
				}
			}
		}
		.party-chart-labels {
			height: 35px;
		}
	
	}
}

@keyframes staplesFromBottom {
	from {
		transform: scaleY(0);
	} to {
		transform: scaleY(1);
	}
}