.privacy {
    padding: 100px;
    padding-bottom: 300px;
    h1 {
        margin: 0;
        font-size: 24px;
        margin-bottom: 30px;
    }
    .paragraph-title {
        margin: 0;
        margin-top: 40px;
        font-size: 17px;
    }
    .paragraph {
        font-size: 14px;
        line-height: 20px;
        margin: 15px 0;
    }
    
    table, th, td {
        vertical-align: top;
        border: 1px solid lightgray;
        border-collapse: collapse;
        font-size: 14px;
        line-height: 20px;
    }
    table {
        margin-top: 15px;
        width: 90%;
    }
    td, th {
        text-align: left;
        padding: 12px;
        width: 30%;
    }
}