.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 300px;
    width: 100%;
    background-color: #ddcebc;
    padding-bottom: 20px;
    .background-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        transform: translateY(-50%);
        .footer-background {
            width: 100%;
            transform-origin: bottom;
            filter: blur(0px);
        }
    }
   
    p {
        a {
            color: rgb(90, 70, 43);
            &:hover {
                color: rgb(250, 250, 250);
            }
        }
    }
}