.checkbox-wrapper {
    display: flex;
    cursor: pointer;
    .checkbox {
        height: 20px;
        width: 20px;
        border-radius: 4px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 1);
        img {
            display: none;
            width: 50%;
            height: 50%;
            padding-bottom: 1px;
        }
        &.checked {
            img {
                display: inline;
            }
        }
        
    }
    label {
        text-transform: capitalize;
        margin-left: 10px;
        pointer-events: none;
    }
}

